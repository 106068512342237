
.left-prata {
  font-family: Prata;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
}

.right-prata {
  font-family: Prata;
  font-size: 20px;
  font-weight: 400;
  line-height: 61px;
  letter-spacing: 0em;
  text-align: center;
}

.prata-heading {
  font-family: Prata;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}