@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-white;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: 'Nunito';
  color: #515151;
  @apply font-light;
}

.grecaptcha-badge { visibility: hidden; }

@layer utilities {

  a, .a {
    text-decoration: #00464A underline;
    @apply text-secondary font-semibold cursor-pointer;
  }

  a:hover, a:visited, .a:hover, .a:visited {
    text-decoration: #333333 underline;
    color: #333333;
  }

  a:focus, .a:focus {
    @apply ring-4 ring-pink-4 ring-opacity-50 p-0.5 rounded;
  }

  textarea:focus {
    @apply ring-4 ring-pink-4 ring-opacity-50;
  }

  textarea:focus-visible {
    outline: none;
  }

  input {
    padding: 6px 14px;
    border-radius: 20px;
    @apply border w-full border-gray-light;
  }

  textarea {
    padding: 6px 14px;
    @apply border w-full border-gray-light outline-none rounded-md;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    padding: 6px 50px 6px 20px;
    border-radius: 20px;
    min-width: 150px;
    background: url("../assets/select_icon.svg") no-repeat;
    background-position: right 12px center;
    @apply border w-full border-gray-light;
  }

  select:focus-visible {
    outline: none; 
  }

  input:focus, select:focus {
    outline: none;
    @apply ring-4 ring-pink-3 ring-opacity-50;
  }

  button, .button {
    padding: 9px 40px;
    @apply text-center justify-center cursor-pointer;
  }

  .button-block {
    @apply flex items-center justify-center mb-10 mt-7;
  }
  input[type="checkbox"], input[type="radio"] {
    accent-color: #00464A;
  }
  .min-width-fit {
    min-width: fit-content;
  }
  .primary-button {
    border-radius: 20px;
    @apply button text-black bg-primary hover:bg-pink-3 uppercase;
  }
  .standard-button {
    border-radius: 20px;
    @apply button text-black bg-pink-3 hover:bg-pink-2 uppercase;
  }

  .secondary-button {
    border-radius: 20px;
    @apply button text-white bg-secondary hover:bg-black uppercase ;
  }

  .primary-button:disabled, .standard-button:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .primary-button:focus, .standard-button:focus {
    @apply ring-2 ring-secondary ring-opacity-50;
  }


  .secondary-button:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .secondary-button:focus {
    @apply ring-2 ring-primary ring-opacity-50;
  }

  .ratings {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    color: #d3d3d3;
    overflow: hidden;
  }
  
  .filled-stars{
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    color: #00464A;
  }
  
  .empty-stars:before,
  .filled-stars:before {
    content: "\2605\2605\2605\2605\2605";
  }

  .bullet-point::before {
    content: "• ";
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .custom-slick-dots {
    width: 100%;
    display: flex !important;
    justify-content: center;
    margin: 0;
    list-style-type: none;
    position: absolute;
    bottom: -40px;
    padding: 0 1rem;
  }

  .custom-slick-dots > li {
    margin: 0 5px;
  }

  .custom-slick-dots > li > button {
    width: 0.50rem;
    height: 0.50rem;
    padding: 0;
    
    border: none;
    border-radius: 100%;    
    text-indent: -9999px;
    background-color: #676767;
  }

  .custom-slick-dots > li:not(.slick-active) > button {
    background-color: #B4B4B4;
  }

  /* Removes counter spin buttons on Mozilla number inputs */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }

  .button-group-button, .button-group-button-rating {
    padding: 6px 14px;
    height: 34px;
    @apply bg-white border-l border-t border-b border-gray-light border-solid whitespace-nowrap text-xs md:text-sm text-black hover:bg-pink-1;
  }

  .button-group-button:first-child, .button-group-button-rating:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .button-group-button:last-child, .button-group-button-rating:last-child{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    @apply border-r;
  }

  .button-group-button-selected, .button-group-button-selected-rating {
    @apply bg-pink-3 ring-1 ring-inset ring-pink-3;
  }

  @media ( max-width: 750px ) {
    .button-group-button, .button-group-button-selected {
      border-radius: 20px;
      @apply m-1;
    }

    .button-group-button-rating, .button-group-button-selected-rating {
      padding: 4px 12px;
    }
  }

}
