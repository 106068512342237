@tailwind utilities;

.hub-heading {
  font-family: Prata;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.hub-subheading {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.hub-title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
}

.hub-tile-border {
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);
  border-radius: 8px; 
}

.hub-alt-text-sm {
  font-size: 11px;
  line-height: 15px;
  color: #00464A;
}

.hub-welcome {
  text-align: center;
  font-family: 'Prata';
  font-size: 24px;
  line-height: 32px;
}

.hub-p {
  font-family: Nunito;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.hub-title {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
}

.hub-clock {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 600;
  /* line-height: 22px; */
  letter-spacing: 0px;
  text-align: left;
}

.hub-btn-small {
  border-radius: 20px;
  padding: 5px 25px;
  background-color: #FFFFFF;
  font-size: 13px;
  color: black;
}

.hub-time-block-p {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.hub-time-block-light {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #B1B1B1;
}

.hub-time-block-light:hover {
  transition: all 0.3s;
  color: #ffffff;
}

.hub-call-to-action-p {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
}