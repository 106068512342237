
.heading {
  font-family: Prata;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
}

.input {
  background: #FFFFFF;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  height: 35px;
  width: 300px;
}

.break {
  border: .5px solid #F0B6B1;
  width: 18rem;
  margin: 8px 0 8px 0;
}

